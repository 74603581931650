'use strict'
const name = 'TimelineAnimation'
const properties = {
    groups: ['animation'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        to: {
            type: 'object',
            properties: {
                x: {
                    type: 'numberLike'
                },
                y: {
                    type: 'numberLike'
                },
                scale: {
                    type: 'number',
                    min: 0
                },
                scaleX: {
                    type: 'number',
                    min: 0
                },
                scaleY: {
                    type: 'number',
                    min: 0
                },
                rotation: {
                    type: 'numberLike'
                },
                direction: {
                    type: 'string',
                    enum: ['cw', 'ccw', 'short']
                },
                opacity: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                autoAlpha: {
                    type: 'number',
                    min: 0,
                    max: 1
                }
            }
        }
    }
}


/**
 * Like lodash pick.
 * @param {object} obj 
 * @param {array} keys 
 */
const pick = (obj, keys) => Object.fromEntries(keys
    .map(key => [key, obj[key]])
    .filter(([, value]) => typeof value !== 'undefined')
)

const definitions = {
    BasePosition: ['x', 'y'],
    BaseScale: ['scale', 'scaleX', 'scaleY'],
    BaseRotate: ['rotation', 'direction'],
    BaseFade: ['opacity', 'autoAlpha']
}
function getFilteredParams(params) {
    return Object.entries(definitions)
        .map(([animationName, keys]) => {
            const animationParams = pick(params, keys)
            if (Object.keys(animationParams).length) {
                return [animationName, animationParams]
            }
            return null
        }).filter(value => value)
}
function register({factory}) {
    /**
     * Timeline animation object
     * supports position, rotate, fade and scale params
     * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [to] Timeline optional parameters.
     * @param {Object} [ease] Timeline optional parameters.
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {to = {}, ease = 'Sine.easeIn', ...params} = {}) {
        const sequence = factory.sequence(params)
        const animations = getFilteredParams(to)
            .map(([animationName, animationParams]) => factory.animate(animationName, elements, duration, delay, {to: animationParams, ease}))
        
        sequence.add(animations)
        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}

